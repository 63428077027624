import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { RewardCriteriaService } from 'src/app/_services/reward-criteria.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/_services/loading.service';
import { AlertController } from '@ionic/angular';
import { io, Socket } from 'socket.io-client';  // Import Socket.IO client
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  selectedAdmin: any | null = null;
  rows: any[] = []; // List of users or admins
  filteredRows: any[] = []; // List of filtered users or admins
  searchText: string = ''; // Search query
  messageText: string = '';
  uploadedSlip: any;
  messages: any[] = []; // Store the fetched messages

  socket: Socket; // Declare socket variable
  private readonly SOCKET_URL = 'http://localhost:3013'; // Correct socket server URL

  constructor(
    private router: Router,
    private alertController: AlertController,
    public loading: LoadingService,
    private rewardCriteriaService: RewardCriteriaService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {
    this.initializeData(); // Initialize data based on user type
  }

  ngOnInit(): void {
    // this.socket = io(this.SOCKET_URL);  // Initialize socket connection
    
    // // Log socket ID after connecting
    // this.socket.on('connect', () => {
    //   console.log('Socket connected: ', this.socket.id);

    //   // Emit the 'register' event after socket connection
    //   const userId = this.authService.currentUser.userId;
    //   this.socket.emit('register', userId);  // Register the user with their ID
    // });

    // // Listen for incoming messages (real-time updates)
    // this.socket.on('receiveMessage', (message: any) => {
    //   console.log('Message received:', message);
    //   this.onMessageReceived(message);  // Handle incoming message
    // });
  }

  ngOnDestroy(): void {
    if (this.socket) {
      this.socket.disconnect();  // Clean up socket connection when component is destroyed
    }
  }

  // Initialize data based on the logged-in user type
  initializeData(): void {
    const userType = this.authService.currentUser.userType;

    if (userType === 6) { // User logged in
      this.refreshAdminList();
    } else if (userType === 2) { // Admin logged in
      this.refreshClientList();
    }
  }

  // Fetch admin/client list and populate data
  refreshClientList(): void {
    let searchQuery = "?userType=6"; // Fetch users
    searchQuery += `&type=${this.authService.currentUser.type}`;

    this.rewardCriteriaService.getListOfAllUsers(searchQuery).subscribe(
      resp => {
        if (resp.errorcode === 0 && resp.resultObj && Array.isArray(resp.resultObj)) {
          this.rows = resp.resultObj.map(user => ({
            id: user.id,
            name: `${user.firstName}`,
            email: user.email,
            profilePicture: 'assets/images/profile.png',
            status: 'Online',
            messages: [] // Initialize empty messages array
          }));
          this.filteredRows = [...this.rows]; // Copy rows to filteredRows initially
        } else {
          console.error('Error fetching client list:', resp.message);
          this.rows = [];
        }
      },
      err => {
        console.error('Error fetching client list:', err);
        this.rows = [];
      }
    );
  }

  refreshAdminList(): void {
    let searchQuery = "?userType=2"; // Fetch admins
    searchQuery += `&type=${this.authService.currentUser.type}`;

    this.rewardCriteriaService.getListOfAllAdmins(searchQuery).subscribe(
      resp => {
        if (resp.errorcode === 0) {
          this.rows = resp.resultObj.map((admin: any) => ({
            id: admin.id,
            name: `${admin.firstName} ${admin.lastName}`,
            email: admin.email,
            profilePicture: 'https://example.com/default-profile.png',
            status: 'Online',
            messages: [] // Initialize empty messages array
          }));
          this.filteredRows = [...this.rows]; // Copy rows to filteredRows initially
        } else {
          console.error('Error fetching admin list:', resp.message);
        }
      },
      err => {
        console.error('Error fetching admin list:', err);
      }
    );
  }

  // Handle receiving new messages
  onMessageReceived(message: any): void {
    if (this.selectedAdmin && message.senderId === this.selectedAdmin.id) {
      this.messages.push(message);
      this.selectedAdmin.messages = this.messages;
      this.cdr.detectChanges();  // Ensure UI updates on new message
    }
  }

  // Call this method when an admin is selected
  selectAdmin(admin: any): void {
    this.selectedAdmin = admin;
    this.messages = [];
    this.getMessages();  // Fetch initial messages when admin is selected
  }

  // Get messages between user and selected admin
  getMessages(): void {
    if (!this.selectedAdmin) return;

    const senderId = this.authService.currentUser.userId;
    const recipientId = this.selectedAdmin.id;

    this.rewardCriteriaService.getMessages(senderId, recipientId).subscribe(
      response => {
        this.messages = response.data;
        this.selectedAdmin.messages = this.messages;
        this.cdr.detectChanges();  // Update UI
      },
      err => {
        console.error('Error fetching messages:', err);
      }
    );
  }

  // Handle sending a message
  sendMessage(fileInput: any): void {
    if (!this.selectedAdmin || !this.messageText.trim()) return;

    const messageData = {
      senderId: this.authService.currentUser.userId,
      recipientId: this.selectedAdmin.id,
      messageText: this.messageText.trim(),
      imageUrl: this.uploadedSlip || null,
    };

    // Emit message to the server
    this.socket.emit('sendMessage', messageData);

    // Optionally handle file upload here...

    // Clear the message input after sending
    this.messageText = '';
  }

  // Filter the rows based on the search text
  filterUsers(): void {
    if (!this.searchText) {
      this.filteredRows = [...this.rows]; // Reset filtered rows if search text is empty
    } else {
      const lowerCaseSearchText = this.searchText.toLowerCase();
      this.filteredRows = this.rows.filter(admin => 
        admin.name.toLowerCase().includes(lowerCaseSearchText) ||
        admin.email.toLowerCase().includes(lowerCaseSearchText)
      );
    }
  }
}

