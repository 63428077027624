import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, MenuController } from '@ionic/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  templateUrl: './customer-layout.page.html',
  styleUrls: ['./customer-layout.page.scss']
})
export class CustomerLayoutPage implements OnInit {
  menus: any = [];
  currentUser: any;
  userMType: any;
  selectedMenu: any;
  notificationsVisible: boolean = false;
  notificationCount = 0; // Variable to hold the count
  previousNotificationCount = 0; // To track the previous count
  notifications: any[] = [];
  notificationSound: HTMLAudioElement;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    public menuController: MenuController,
    private cdRef: ChangeDetectorRef
  ) {
    this.initializeApp();
  }

  ngOnInit() {
   
  }

  openMenu() {
    this.menuController.open('end');
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.setDefaultLang(event.lang);
        this.menus = event.translations.CUSTOMERMENUS;
      });

      this.translate.get(['CUSTOMERMENUS']).subscribe((data) => {
        this.menus = data.CUSTOMERMENUS;
      });

      let findUserType = JSON.parse(localStorage.getItem('authUser'));
      this.userMType = "Main";
      this.authService.onCurrentUserChange.subscribe(user => {
        this.currentUser = user;   
      });


  
    });
  }


  gotoProfile() {
    this.router.navigate(['customer/profile']);
  }

  gotoHome() {
    this.router.navigate(['/customer']);
  }

  signout() {
    this.menuController.close();
    this.authService.logout();
  }

}
